import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Section, Wrapper } from '../components/baseStyles'
import Img from 'gatsby-image'
import CustomButton from './button'
import SocialIcons from './socialIcons'
import LocalisedLink from '../components/localisedLink'
import { FormattedMessage } from 'react-intl'
import SEO from '../components/seo'

const ExtendedSection = styled(Section)`
  width: calc((100% / 12) * 10);
  margin: 0 auto;
  @media screen and (max-width: 560px) {
    padding: 0;
  }
`
const Image = styled(Img)`
  width: 100%;
  max-height: 550px;
  margin-bottom: 45px;

  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 25px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
`

const ExtendedWrapper = styled(Wrapper)`
  position: relative;
  top: -130px;
  z-index: 2;
`
export default class postLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data
    return (
      <Layout
        isBlogPost={true}
        locale={'hr'}
        title={markdownRemark.frontmatter.title}
      >
        <SEO
          title={markdownRemark.frontmatter.seoTitle}
          description={markdownRemark.frontmatter.seoMetaDesc}
          imageUrl={
            markdownRemark.frontmatter.image &&
            markdownRemark.frontmatter.image.childImageSharp &&
            markdownRemark.frontmatter.image.childImageSharp.fluid.src
          }
          postURL={markdownRemark.frontmatter.slug}
          isBlogPost
        />
        <ExtendedWrapper>
          <ExtendedSection>
            <div style={{ position: 'relative' }}>
              <Image
                fluid={markdownRemark.frontmatter.image.childImageSharp.fluid}
              />
            </div>
            <div
              className="blog-wrapper"
              id="count-images"
            >
              <SocialIcons
                bottom={true}
                title={markdownRemark.frontmatter.title}
                url={this.props.location.href}
              />
              <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }}/>
            </div>
          </ExtendedSection>

          <div style={{ textAlign: 'center' }}>
            <LocalisedLink to="/novosti-iz-turizma-i-vodici-za-iznajmljivace">
              <CustomButton black yellow back>
                <FormattedMessage id="backToNews" />
              </CustomButton>
            </LocalisedLink>
          </div>
        </ExtendedWrapper>
      </Layout>
    )
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        slug
        seoTitle
        seoMetaDesc
        image {
          absolutePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
      }
    }
  }
`
