import React from 'react';
import facebook from '../images/social/facebook-icon.svg'
import facebookActive from '../images/social/facebook-icon-active.svg'
import twitter from '../images/social/twitter-icon.svg'
import twitterActive from '../images/social/twitter-icon-active.svg'
import linkedIn from '../images/social/linkedin-icon.svg'
import linkedInActive from '../images/social/linkedin-icon-active.svg'
import styled, { css } from 'styled-components'
import Paragraph from './paragraph'

const SocalList = styled.ul`
  list-style: none;
  width: 100%!important;
  padding:0!important;
  transform: translateX(-150%);
  
  @media screen and (max-width: 768px){
    display: flex;
    justify-content: center;
    margin: 0 0 20px!important;
    transform: none;
  }
`
const ExtendedParagraph = styled(Paragraph)`
  width: calc(100% - 20%);
  margin:0 auto 10px auto;
  color: grey;
  display: none;
  @media screen and (max-width:768px){
    width:100%
  }
  
`
const ListItem = styled.li`
  max-width:30px;
  margin-bottom:15px;
  width:30px;
  margin: 20px 0px;
  ${props => props.bottom && css` 
    margin-right:20px;
    margin-bottom:0px;
  `}
  @media screen and (max-width:768px){
    margin-right:20px;
    margin-bottom:0px;
  }
`
const Link = styled.a`
  display: block;
  transition:all ease 1s;
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  max-width:30px;
  ${props => props.facebook && css` 
  background-image: url(${facebook});
  height: 24px;
  &:hover{
    background-image: url(${facebookActive});
    transition:all ease 1s;
    }
  `}

  ${props => props.twitter && css` 
  background-image: url(${twitter});
  height: 24px;
  &:hover{
    background-image: url(${twitterActive});
    transition:all ease 1s;
    }
  `}

  ${props => props.linkedIn && css` 
  background-image: url(${linkedIn});
  height: 24px;
  &:hover{
    background-image: url(${linkedInActive});
    transition:all ease 1s;
    }
  `}
  
  `
  const SocialWrapper = styled.div`
    position: sticky;
    top: 60px;
    float: left;
    height: 0;
    margin-bottom: 200px;
    transform: translateY(-10px);

    @media screen and (max-width: 768px){
      float: none;
      margin: 0 auto;
      height: auto;
      position: static;
      order: 2;
    }
    @media screen and (max-width: 460px){
      width: 100%;
      padding: 0 30px;
      margin: 0 auto;
    }
  `

function openInPopUp(e) {

  e.preventDefault();
  e.stopPropagation();

  var $link = e.target;

  var href = $link.getAttribute('href');
  var network = $link.getAttribute('title');

  var networks = {
    Facebook: { width: 980, height: 400 },
    Twitter: { width: 600, height: 254 },
    Linkedin: { width: 600, height: 473 }
  };

  var options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';

  PopupCenter(href, networks[network].width, networks[network].height, options);
}


function PopupCenter(url, w, h, options) {
  var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : null;
  var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : null;

  var left = ((width / 2) - (w / 2)) + dualScreenLeft;
  var top = ((height / 2) - (h / 2)) + dualScreenTop;

  var newWindow = window.open(url, '', options + 'height=' + h + ',width=' + w + ',top=' + top + ', left=' + left);

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}


const SocialIcons = (props) => {
  return (
    <SocialWrapper>
      {props.bottom && <ExtendedParagraph>Podijeli na društvenim mrežama</ExtendedParagraph>}
      <SocalList bottom={props.bottom}>
        <ListItem bottom={props.bottom}>
          <Link
            title="Facebook"
            onClick={openInPopUp}
            facebook={true}
            href={`https://www.facebook.com/sharer.php?u=${props.url}`}
          >
          </Link>
        </ListItem>
        <ListItem bottom={props.bottom}>
          <Link
            onClick={openInPopUp}
            title="Twitter"
            twitter={true}
            href={`https://twitter.com/intent/tweet?url=${props.url}%2F&text=${props.title}`}></Link>
        </ListItem>
        <ListItem bottom={props.bottom}>
          <Link
            onClick={openInPopUp}
            title="Linkedin"
            linkedIn={true}
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${props.url}`}></Link>
        </ListItem>
      </SocalList>
    </SocialWrapper>
  )
}

export default SocialIcons;